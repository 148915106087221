import { mapState } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import EventBus from '@/eventBus.js';

export default {
  data() {
    const appCode = process.env.VUE_APP_CODE;
    return {
      resetMeasurements: true,
      appCode,
      apiUrl: {
        settings: ':8766/api/v1/settings',
        license: ':8769/license',
        images: ':8766/images',
        reports_sensors: ':8766/api/v1/reports/sensors',
        reports_measurements: ':8766/api/v1/reports/measurements',
        reports_download: ':8766/api/v1/reports/download',
        reports_images: ':8766/api/v1/reports/images',
        reports_state_journal: ':8766/api/v1/reports/state_journal',
        reports_alerts: ':8766/api/v1/reports/alerts',
      },
      countriesDictionary: [
        'ABH',
        'AUT',
        'AZE',
        'ALB',
        'AND',
        'ARM',
        'BLR',
        'BEL',
        'BGR',
        'BIH',
        'VAT',
        'GBR',
        'HUN',
        'DEU',
        'NLD',
        'GRC',
        'GEO',
        'DNK',
        'ISR',
        'IRN',
        'IRL',
        'ISL',
        'ESP',
        'ITA',
        'KAZ',
        'CHN',
        'RKS',
        'KGZ',
        'LVA',
        'LTU',
        'LIE',
        'LUX',
        'MDA',
        'MCO',
        'MNG',
        'NOR',
        'POL',
        'PRT',
        'MKD',
        'RUS',
        'ROU',
        'SMR',
        'PRK',
        'SRB',
        'SVK',
        'SVN',
        'TUR',
        'UKR',
        'FIN',
        'FRA',
        'HRV',
        'MNE',
        'CZE',
        'CHE',
        'SWE',
        'EST',
        'KOR',
        'OST',
        'JPN',
        'PMR',
        'SUN',
        'UZB',
        'TJK',
      ],
      categoryBySickMinAxles: {
        210: 2,
        211: 2,
        310: 2,
        311: 2,
        320: 2,
        321: 3,
        330: 2,
        331: 3,
        340: 2,
        410: 2,
        411: 3,
        420: 2,
        421: 3,
        430: 2,
        431: 3,
        440: 2,
        441: 3,
        450: 0,
        451: 4,
        510: 3,
        520: 3,
        530: 3,
        540: 3,
        590: 2,
        610: 2,
        611: 2,
        710: 2,
      },
      truckImagePath: require.context(
        '@/assets/img/trucks/ectn-full-white/',
        false,
        /\.png$/,
      ),
      flagImagePath: require.context(
        '@/assets/img/flags/',
        false,
        /\.png$/,
      ),

      dscrTypes: {
        ЗСД: 'type-4.png',
        АПД: 'type-1-1.png',
        НКД: 'type-2.png',
        ЗСКК: 'type-5.png',
        СЗКК: 'type-5.png',
      },
    };
  },
  computed: {
    ...mapState([
      'timezone',
      'settings',
    ]),
    manualAnalysisValues() {
      if (this.appCode === 'ind') {
        return [
          {
            key: 'is_plate_valid',
            id: '1',
            error: false,
          },
          {
            key: 'is_classification_valid',
            id: '2',
            error: false,
          },
          {
            key: 'has_many_errors',
            id: '4',
            error: true,
          },
        ];
      }
      return [
        {
          key: 'is_plate_valid',
          id: '1',
          error: false,
        },
        {
          key: 'is_classification_valid',
          id: '2',
          error: false,
        },
        {
          key: 'is_dsrc_valid',
          id: '3',
          error: false,
        },
        {
          key: 'is_gp_valid',
          id: '5',
          error: false,
        },
        {
          key: 'has_many_errors',
          id: '4',
          error: true,
        },
      ];
    },
    weighterValidityValues() {
      return [
        {
          key: 'weighter_validity_0',
          id: 'valid',
          error: false,
        },
      ];
    },
  },
  methods: {
    checkLogin() {
      if (localStorage.token === '' || localStorage.token === 'undefinded' || !localStorage.token) {
        localStorage.token = '';
        this.$router.push('/login');
      }
    },
    getCarImage(measurement) {
      let url = '';
      if (!measurement.category_by_sick) {
        if (!measurement.class_autodor) {
          url = '';
        } else
        if (measurement.class_autodor === 1) {
          url = '210-2.png';
        } else
        if (measurement.class_autodor === 2) {
          url = '310-2.png';
        } else
        if (measurement.class_autodor === 3) {
          url = '410-2.png';
        } else
        if (measurement.class_autodor === 4) {
          url = '410-4.png';
        }
        return url;
      }

      // eslint-disable-next-line no-underscore-dangle
      const _ss = measurement.category_by_sick.toString();
      url = `${_ss}-${measurement.axles_probable_value}.png`;
      if (_ss === '110') {
        url = '110.png';
        return url;
      }
      if (
        [0, 1, 2].includes(measurement.axles_probable_value)
        && _ss === '510'
      ) {
        url = `${_ss}-3.png`;
      }
      if (
        [0, 1].includes(measurement.axles_probable_value)
        && measurement.class_autodor === 3
        && [
          '210',
          '211',
          '310',
          '320',
          '330',
          '340',
          '410',
          '420',
          '430',
          '440',
          '450',
          '590',
          '610',
          '611',
          '710',
          '711',
        ].includes(_ss)
      ) {
        url = `${_ss}-2.png`;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.categoryBySickMinAxles) {
        if (
          measurement.axles_probable_value < this.categoryBySickMinAxles[key]
          && measurement.category_by_sick === key
        ) {
          url = `${_ss}-${this.categoryBySickMinAxles[key]}.png`;
        }
      }
      if (['610', '611', '710', '711'].includes(_ss) && measurement.axles_probable_value > 2) {
        url = `${_ss}-2+.png`;
      }
      if (['210', '211', '310', '590'].includes(_ss) && measurement.axles_probable_value > 3) {
        url = `${_ss}-3+.png`;
      }
      if (['311', '321'].includes(_ss) && measurement.axles_probable_value > 4) {
        url = `${_ss}-4+.png`;
      }
      if (['340', '440'].includes(_ss) && measurement.axles_probable_value > 5) {
        url = `${_ss}-5+.png`;
      }
      if (['411', '421', '431', '441', '450', '451', '510', '520', '530', '540'].includes(_ss) && measurement.axles_probable_value > 6) {
        url = `${_ss}-6+.png`;
      }

      return url;
    },
    getTruckImage(measurement) {
      const carImage = this.getCarImage(measurement);
      let truckImage = null;
      try {
        truckImage = this.truckImagePath(`./${carImage}`);
      } catch (e) {
        if (carImage.includes('-')) {
          truckImage = this.truckImagePath(`./${carImage.split('-')[0]}.png`);
        }
      }
      return truckImage;
    },
    manualAnalysis(currentMeasurement) {
      return this.manualAnalysisValues.map((value) => {
        if (currentMeasurement[value.key] === value.error && value.id !== '4') {
          return value.id;
        }
        return '';
      }).join('');
    },
    sentClass(measurement) {
      let className;
      if (!measurement) {
        className = '';
      } else
      if (measurement.sent === true) {
        className = 'mdi-action-done';
      } else
      if (measurement.sent === false) {
        className = 'glyphicon glyphicon-hourglass';
      } else {
        className = 'mdi-content-clear mdi-content-clear--green';
      }
      return className;
    },
    measurementClass(measurement) {
      let measurementClass = '';
      if (this.appCode === 'ind' && (measurement.is_total_overweight === true || measurement.is_width_violation || measurement.is_height_violation || measurement.is_length_violation)) {
        measurementClass += ' overweight-ind';
      }
      return measurementClass;
    },
    // eslint-disable-next-line consistent-return
    measurementAnnotation(measurement) {
      let measurementAnnotation = '';
      if (this.settings.weighter_validity) {
        if (measurement.weighter_validity > 0) {
          measurementAnnotation = measurement.weighter_validity.toString();
        }
        return measurementAnnotation;
      }
    },
    measurementBorderClass(measurement) {
      let measurementClass = '';
      if (this.settings.weighter_validity) {
        if (measurement.weighter_validity > 0) {
          measurementClass += ' yellow color-yellow';
        }
      }
      return measurementClass;
    },
    getUnique(arr, comp) {
      return arr
        .map((e) => e[comp])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e) => arr[e])
        .map((e) => arr[e]);
    },
    getNewLength(arrNew, arrOld, comp) {
      let num = 0;
      const arrNewComp = arrNew.map((e) => e[comp]);
      const arrOldComp = arrOld.map((e) => e[comp]);
      arrNewComp.forEach((item) => {
        if (arrOldComp.indexOf(item) === -1) {
          num += 1;
        }
      });
      return num;
    },
    measurementAddParameters(value) {
      const params = {};
      const imgUrl = `${this.getApiUrl('images')}/${moment(value.datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY_MM_DD/HH/')}`;
      const imagesObj = {};
      JSON.parse(value.images_type).forEach((item) => {
        imagesObj[item] = `${imgUrl}${value.measurement_id}_${item}`;
      });
      const imagesArr = [];
      [0, 1, 3, 4, 2, 20, 21, 5, 52, 6, 61, 50, 51, 60].forEach((item) => {
        if (imagesObj[item]) {
          imagesArr.push(imagesObj[item]);
        }
      });
      if (imagesObj[0]) {
        params.playerId = imagesArr.length;
        imagesArr.push(imagesObj[0]);
        params.playerOptions = {
          muted: true,
          language: 'en',
          autoplay: true,
          fluid: true,
          playbackRates: [0.1, 0.5, 1],
          sources: [
            {
              type: 'video/mp4',
              src: `${imgUrl}${value.uragan_uuid}_0.mp4`,
            },
          ],
          poster: imagesObj[0],
        };
      }
      if (imagesObj[3]) {
        params.player2Id = params.playerId + 1;
        imagesArr.push(imagesObj[3]);
        params.playerOptions2 = {
          muted: true,
          language: 'en',
          autoplay: true,
          fluid: true,
          playbackRates: [0.1, 0.5, 1],
          sources: [
            {
              type: 'video/mp4',
              src: `${imgUrl}${value.uragan_uuid}_1.mp4`,
            },
          ],
          poster: imagesObj[3],
        };
      }
      params.imagesObj = imagesObj;
      params.imagesArr = imagesArr;
      params.flag = this.measurementFlag(value.gnz_country);
      params.flagBack = this.measurementFlag(value.back_cam_gnz_country);
      params.flagAlt = this.measurementFlag(value.roadar_country);
      return params;
    },
    measurementFlag(country) {
      let img = null;
      if (country && country !== '???' && country !== ' ') {
        try {
          img = this.flagImagePath(`./${country.toLowerCase()}.png`);
        } catch (e) {
          return null;
        }
      }
      return img;
    },
    getApiUrl(type, prod) {
      if (this.apiUrl[type]) {
        if (process.env.NODE_ENV === 'development' && !prod) {
          return this.apiUrl[type].replace(':8966', '/report');
        }
        return `http://${window.location.hostname}${this.apiUrl[type]}`;
      }
      return null;
    },
    getLicenseUrl() {
      return `http://${window.location.hostname}:8070/license`;
    },
    sendRequest(data, wait) {
      return new Promise((resolve, reject) => {
        const sendedData = { ...data };
        if (sendedData.index === undefined) {
          sendedData.index = this.$store.state.wsIndex;
        }
        try {
          this.$socket.send(JSON.stringify(sendedData));
        } catch (e) {
          this.$options.sockets.onopen = () => this.$socket.send(JSON.stringify(sendedData));
        }
        if (wait) {
          EventBus.$emit('set-loading', true);
        }
        this.$store.commit('wsSend', {
          index: sendedData.index,
          resolve,
          reject,
          wait,
        });
      });
    },
    downloadFile(url, data, type = 'application/vnd.ms-excel') {
      EventBus.$emit('set-loading', true);
      const headers = { responseType: 'blob' };
      axios
        .post(
          url,
          data,
          headers,
        )
        .then((response) => {
          const name = response.headers['content-type'].split('=')[1].replace(/"/g, '');
          const fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type }),
          );
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', name);
          document.body.appendChild(fileLink);
          fileLink.click();
          EventBus.$emit('set-loading', false);
        })
        .catch((error) => {
          console.error(error);
          EventBus.$emit('set-loading', false);
        });
    },
    clearFilters() {
      Object.keys(this.filters).forEach((key) => {
        if (Array.isArray(this.filters[key])) {
          this.filters[key] = [];
        } else
        if (typeof this.filters[key] === 'string') {
          this.filters[key] = null;
        } else
        if (typeof this.filters[key] === 'boolean') {
          this.filters[key] = false;
        } else {
          this.filters[key] = null;
        }
      });
    },
    saveFilters(obj) {
      this.filters = obj;
    },
    formatTime(value) {
      return value ? value.format('DD.MM.YYYY HH:mm:ss') : null;
    },
    getOffsetTime(value) {
      return value ? moment(value).subtract(this.timezone, 'hours').format('YYYY-MM-DD HH:mm:ss') : null;
    },
    getPercentText(value, multiply = 1, placeholder = '') {
      return value !== undefined && value !== null ? `${Math.round(value * multiply)}%` : placeholder;
    },
    getFormattedValues(measurement, arr) {
      if (!arr.filter((item) => (measurement[item] !== undefined && measurement[item] !== null && measurement[item] !== '')).length) {
        return null;
      }
      return arr.map((item) => (measurement[item] !== undefined && measurement[item] !== null && measurement[item] !== '' ? measurement[item] : '-')).join(' / ');
    },
    getFormattedValue(measurement, key) {
      return measurement[key] !== undefined && measurement[key] !== null && measurement[key] !== '' ? measurement[key] : '-';
    },
    getCarDimensionsText(measurement) {
      return this.getFormattedValues(measurement, ['car_length', 'car_width', 'car_height']);
    },
    getCarBrandModelText(measurement) {
      return this.getFormattedValues(measurement, ['brand', 'model']);
    },
    parseJwt(token) {
      if (!token) {
        return false;
      }
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join(''),
      );
      return JSON.parse(jsonPayload);
    },
    roleIn(roles) {
      return roles.includes(this.parseJwt(localStorage.token).user.role);
    },
  },
  filters: {
    momentFilter(value, offset) {
      return value ? moment(value, 'YYYY-MM-DD HH:mm:ss', 'UTC').add(offset, 'hours').format('DD.MM.YYYY HH:mm:ss') : null;
    },
  },
};
