import mixin from '@/components/mixins/mixin';
import { mapState } from 'vuex';

export default {
  name: 'Hardware',
  mixins: [mixin],
  computed: {
    ...mapState([
      'settings',
      'currentState',
      'isLoginned',
    ]),
    isAdmin() {
      return localStorage.token && this.parseJwt(localStorage.token).user.role === 'admin';
    },
  },
  mounted() {
    if (this.currentState !== 'SERVICE') {
      this.$router.push({ name: 'panel' });
    }
    if (this.isAdmin !== true) {
      this.$router.push({ name: 'panel' });
    }
    this.checkLogin();
  },
};
