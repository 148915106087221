<template>
  <div v-if="isAdmin" class="center-block col-6">
    <br>
    <br>
    <div v-if="isLoading">
      Загрузка ..
    </div>
    <form v-else @submit.stop.prevent="setSettings" class="form" name="consulForm">
      <div v-for="setting in currentSettings" :key="setting.key" class="form-group">
        <div v-if="setting.is_hidden === false">
          <div v-if="setting.field === 'header'">
            <h4 class="center-block">{{ setting.name }}</h4>
          </div>
          <div v-else-if="setting.field === 'text'">
            <label>{{ setting.name }}</label>
            <input
              type="text"
              v-model="setting.value"
              class="form-control"
            />
          </div>
          <div v-else-if="setting.field === 'textarea'">
            <label>{{ setting.name }}</label>
            <textarea v-model="setting.value" class="form-control" name="name" rows="3"></textarea>
          </div>
          <div v-else-if="setting.field === 'json'">
            <label>{{ setting.name }}</label>
            <VJsoneditor v-model="setting.value" :options="{mode: 'code', mainMenuBar: false, theme: 'ace/theme/dracula'}" :plus="false" height="70vh"/>
          </div>
          <div v-else-if="setting.field === 'bool'">
            <label>{{ setting.name }}</label>
            <select class="form-control" v-model="setting.value">
              <option value="True">Да</option>
              <option value="False">Нет</option>
            </select>
          </div>
          <div v-else-if="setting.field === 'options'">
            <label>{{ setting.name }}</label>
            <select class="form-control" v-model="setting.value">
              <option v-for="(option, option_index) in setting.options" :key="option_index"
                      :value="option.value">{{ option.text }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <br>
      <br>
      <br>
      <button type="submit" class="btn btn-primary center-block"
              ng-disabled="!isChanged || isFething">
        {{ $t('action.save') }}
      </button>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixin from '@/components/mixins/mixin';
import VJsoneditor from 'v-jsoneditor';

export default {
  name: 'Frame',
  mixins: [mixin],
  components: {
    VJsoneditor,
  },
  data() {
    return {
      turnOn: false,
      isLoading: true,
      currentSettings: {},
    };
  },
  created() {
    console.log('created');
    if (localStorage.token) {
      this.getSettings();
    }
  },
  // beforeMount() {
  //   if (localStorage.token) {
  //     this.getSettings();
  //   }
  // },
  computed: {
    ...mapState([
      'settings',
      'isLoginned',
    ]),
    isAdmin() {
      return localStorage.token && this.parseJwt(localStorage.token).user.role === 'admin';
    },
  },
  methods: {
    setSettings() {
      console.log('setSettings');
      console.log(this.currentSettings);
      const request = {
        method: 'configs.set_settings_key',
        token: localStorage.token,
        data: this.currentSettings,
      };
      this.sendRequest(request).then((data) => {
        console.log(data);
        this.getSettings();
      });
    },
    getSettings() {
      this.isLoading = true;
      const request = {
        method: 'configs.get_available_settings',
        token: localStorage.token,
        data: {},
      };
      this.sendRequest(request).then((data) => {
        console.log('response');
        this.currentSettings = data.data;
        this.isLoading = false;
      });
    },
  },
};
</script>
