import constructorEdit from '@/components/mixins/constructorEdit';
import Grid from '@/components/Grid/Grid.vue';

export default {
  name: 'Source',
  components: {
    Grid,
  },
  mixins: [
    constructorEdit,
  ],
  data() {
    return {
      field: 'sources',
      ruleForm: {
        Name: '',
        Protocol: 'snmp',
        Host: '',
        Port: '',
        Login: '',
        Password: '',
        Timeout: '',
        Interval: '',
        Delay: '',
        Community: '',
        Version: '',
        Retries: '',
        Iface: '',
        Database: '',
        Device: '',
      },
      emptyRuleForm: {
        Name: '',
        Protocol: 'snmp',
        Host: '',
        Port: '',
        Login: '',
        Password: '',
        Timeout: '',
        Interval: '',
        Delay: '',
        Community: '',
        Version: '',
        Retries: '',
        Iface: '',
        Database: '',
        Device: '',
      },
      // pregHost: /^\d{1,3}(\.\d{1,3}){0,4}$/,
    };
  },
  watch: {
    showModal(val) {
      if (!val) {
        this.ruleForm = this.emptyRuleForm;
      }
    },
  },
  computed: {
    records() {
      return this.baseRecords.map((item) => ({
        ...item,
        notProtocol: item.Protocol && !this.protocolList.includes(item.Protocol),
        // notHost: item.Host && !this.pregHost.test(item.Host),
      }));
    },
    isValid() {
      if (this.showFields.community && !this.ruleForm.Community) return false;
      if (this.showFields.version && !this.ruleForm.Version) return false;
      if (this.showFields.device && !this.ruleForm.Device) return false;
      return this.ruleForm.Name && this.ruleForm.Protocol && !this.records.find((item, i) => item.name === this.ruleForm.Name && i !== this.ruleFormRowIndex);
    },
    showFields() {
      let arr = [];
      switch (this.ruleForm.Protocol.toLowerCase()) {
        case 'snmp': arr = ['host', 'port', 'delay', 'community', 'version']; break;
        case 'ntp': arr = ['host', 'delay']; break;
        case 'systemstate': arr = ['host', 'port', 'delay']; break;
        case 'zabbix': arr = ['host', 'port', 'timeout', 'delay']; break;
        case 'uragan': arr = ['host', 'login', 'delay']; break;
        case 'advantech': arr = ['host', 'port', 'delay', 'retries', 'iface']; break;
        case 'rest': arr = ['host', 'timeout', 'delay']; break;
        case 'frameversion': arr = ['host', 'port', 'login', 'password', 'delay', 'database']; break;
        case 'frameboot': arr = []; break;
        case 'shtil': arr = ['host', 'port', 'password', 'timeout', 'delay', 'retries']; break;
        case 'calculation': arr = ['timeout', 'delay']; break;
        case 'local': arr = ['timeout']; break;
        case 'mercury236': arr = ['device', 'interval']; break;
        case 'mercury236log': arr = ['device', 'interval']; break;
        case 'text': arr = []; break;
        case 'db': arr = []; break;
        case 'icmp': arr = ['host']; break;
        default: arr = []; break;
      }
      const result = {};
      arr.forEach((item) => {
        result[item] = true;
      });
      return result;
    },
  },
  methods: {
    onSubmitForm(ruleForm) {
      const result = {};
      Object.keys(ruleForm).forEach((item) => {
        const key = item.toLowerCase();
        if (['name', 'protocol'].includes(key) || this.showFields[key]) {
          let val = ruleForm[item];
          if (['Port', 'Timeout', 'Delay', 'Version', 'Retries', 'Interval'].includes(item)) val = +val;
          result[item] = val;
        }
      });
      if (this.ruleFormRowIndex < 0) return result;
      const { Name: name } = this.json[this.field][this.ruleFormRowIndex];
      if (name === ruleForm.Name) return result;
      this.json.metrics = this.json.metrics.map((item) => {
        if (!item.source || item.source !== name) return item;
        return {
          ...item,
          source: ruleForm.Name,
        };
      });

      return result;
    },
  },
};
