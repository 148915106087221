<template>
  <div class="">
    <div v-if="isLoading">
      Загрузка ..
    </div>
    <form v-else @submit.stop.prevent="setSettings" class="form">
      <div class="form-group">
        <div class="checkbox">
          <label class="">
            <span class="checkbox-material"
              ><span
                class="check black"
                :style="{
                  background: turnOn
                    ? 'url(' + require('../../assets/img/icons/check-blue.svg') + ')  center '
                    : ''
                }"
              >
              </span
            ></span>
            <input disabled v-model="turnOn" type="checkbox" />
            {{ $t('ac.On_Off') }}
          </label>
        </div>
      </div>
      <div class="form-group">
        <label for="" class="">{{ $t('ac.highest_border') }}</label>
        <input
          min="40"
          max="60"
          type="number"
          required
          v-model="params.DKCUpperBound"
          class="form-control"
          id=""
        />
      </div>
      <div class="form-group">
        <label for="" class="">{{ $t('ac.lower_border') }}</label>
        <input
          type="number"
          min="5"
          max="20"
          name="LowerBound"
          required
          v-model="params.DKCLowerBound"
          class="form-control"
          id=""
        />
      </div>
      <div class="form-group">
        <label for="" class="">{{ $t('ac.Hysteresis') }}</label>
        <input
          type="number"
          required
          min="1"
          max="40"
          v-model="params.DKCHysteresis"
          class="form-control"
          id=""
        />
      </div>
      <div class="form-group">
        <label for="" class="">{{ $t('ac.compressor_start_temperature') }}</label>
        <input
          type="number"
          min="20"
          max="45"
          v-model="params.DKCCompressorTurnOnTemp"
          class="form-control"
          id=""
        />
      </div>
      <button type="submit" class="btn btn-primary" ng-disabled="!isChanged || isFething">
        {{ $t('action.save') }}
      </button>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

export default {
  name: 'AC',
  data() {
    return {
      turnOn: false,
      isLoading: true,
      errors: [],
      params: {
        DKCCompressorTurnOnTemp: 30,
        DKCHysteresis: 4,
        DKCLowerBound: 15,
        DKCOutputStateCompressor: 1,
        DKCUpperBound: 50,
      },
    };
  },
  computed: {
    ...mapState([
      'settings',
    ]),
  },
  created() {
    if (localStorage.token) {
      setTimeout(() => this.getSettings(), 7000);
    }
  },
  mounted() {
    if (localStorage.token) {
      setTimeout(() => this.getSettings(), 7000);
    }
  },
  methods: {
    getSettings() {
      this.isLoading = true;
      axios.get(this.settings.ac_url)
        .then((response) => {
          if (response.status === 200) {
            if (this.params.DKCOutputStateCompressor === 1) {
              this.turnOn = true;
            } else {
              this.turnOn = false;
            }
            this.params = response.data;
          }
          this.isLoading = false;
        });
    },
    setSettings() {
      if (this.turnOn) {
        this.params.DKCOutputStateCompressor = 1;
      } else {
        this.params.DKCOutputStateCompressor = 0;
      }
      this.params.DKCCompressorTurnOnTemp = parseInt(this.params.DKCCompressorTurnOnTemp, 10);
      this.params.DKCHysteresis = parseInt(this.params.DKCHysteresis, 10);
      this.params.DKCLowerBound = parseInt(this.params.DKCLowerBound, 10);
      this.params.DKCOutputStateCompressor = parseInt(this.params.DKCOutputStateCompressor, 10);
      this.params.DKCUpperBound = parseInt(this.params.DKCUpperBound, 10);
      axios
        .post(this.settings.ac_url, this.params)
        .then((res) => {
          console.log(res);
          this.getSettings();
        })
        .catch((err) => {
          console.log(err);
          this.getSettings();
        });
    },
  },
};
</script>
