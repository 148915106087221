// import axios from 'axios';
import Grid from '@/components/Grid/Grid.vue';
// import moment from 'moment';

export default {
  name: 'Options',
  components: {
    Grid,
  },
  data() {
    return { };
  },
  created() {},
  computed: {},
  methods: {},
};
